import { D3Graph, D3Node } from '@swimlane/ngx-graph';
import { ArticleNodeAuthor, Publisher, RelatedTo } from '../api/backend-connector/models';

export const getPointFromEvent = (event: TouchEvent | MouseEvent, svg: SVGGraphicsElement) => {
  const point = new DOMPoint();
  if (event instanceof TouchEvent) {
    point.x = event.targetTouches[0].clientX;
    point.y = event.targetTouches[0].clientY;
  } else {
    point.x = event.clientX;
    point.y = event.clientY;
  }

  const invertedSVGMatrix = svg.getScreenCTM()?.inverse();
  return point.matrixTransform(invertedSVGMatrix);
};

export const nodeIntoSelection = (nodes: (CustomNode & D3Node)[], rect: DOMRect, svg: SVGGraphicsElement) => {
  const pointA = new DOMPoint();
  const pointB = new DOMPoint();
  pointA.x = rect.x;
  pointA.y = rect.y;
  pointB.x = rect.x + rect.width;
  pointB.y = rect.y + rect.height;

  const invertedSVGMatrix = svg.getScreenCTM()?.inverse();
  const pointTA = pointA.matrixTransform(invertedSVGMatrix);
  const pointTB = pointB.matrixTransform(invertedSVGMatrix);

  return nodes.filter((n) => pointTA.x < n.x && n.x < pointTB.x && pointTA.y < n.y && n.y < pointTB.y);
};

export const getPointFromLayedOutGraph = (nodes: string[], graph: { nodes: (CustomNode & D3Node)[] } & D3Graph) => {
  const layedOutNodes = graph.nodes.filter((n): n is CustomNode & D3Node => nodes.includes(n.id));

  const minx = Math.min(...layedOutNodes.map((n) => n.x));
  const miny = Math.min(...layedOutNodes.map((n) => n.y));
  const maxx = Math.max(...layedOutNodes.map((n) => n.x));
  const maxy = Math.max(...layedOutNodes.map((n) => n.y));

  const point = new DOMPoint();
  point.x = minx + (maxx - minx) * 0.5;
  point.y = miny + (maxy - miny) * 0.5;

  return { point, width: maxx - minx, height: maxy - miny };
};

export const calcViewSize = (rect: DOMRectReadOnly, bbox: { x: number; y: number; width: number; height: number }) => {
  let minx = bbox.x;
  let miny = bbox.y;
  let maxx = bbox.x + bbox.width;
  let maxy = bbox.y + bbox.height;

  // assumes bbox.width == bbox.height
  if (rect.height < rect.width) {
    // wider than tall
    const center = 0.5 * (minx + maxx);
    const width = (bbox.width * rect.width) / rect.height;
    minx = center - width * 0.5;
    maxx = center + width * 0.5;
  } else {
    // taller than wide
    const center = 0.5 * (miny + maxy);
    const height = (bbox.height * rect.height) / rect.width;
    miny = center - height * 0.5;
    maxy = center + height * 0.5;
  }
  return { minx, maxx, miny, maxy };
};

export const fullTextSearch = (A: string, B?: string) => {
  const regex = new RegExp(`\\b${A}\\b`, 'i');
  return B ? regex.test(B) : false;
};

export type CustomNode =
  | {
      date?: string | null;
      id: string;
      authors: Array<ArticleNodeAuthor>;
      publisher?: Publisher;
      relatedTo: Array<RelatedTo>;
      title?: string | null;
      label: string | null | undefined;
      color?: string | null;
      isTopic: false;
    }
  | {
      articlesCount: number;
      id: string;
      relatedTo: Array<RelatedTo>;
      title?: string | null;
      label: string | null | undefined;
      color?: string | null;
      isTopic: true;
    };

export const colors = [
  '#22D68D',
  '#1FD096',
  '#1DCA9F',
  '#1AC4A9',
  '#18BEB2',
  '#15B7BB',
  '#13B1C4',
  '#10ABCE',
  '#0EA5D7',
  '#0B9FE0',
];

export const TOPIC = '#64748b';
export const ARTICLE = '#94a3b8';
export const LINKLESS_ARTICLE = '#cbd5e1';
export const UNFOCUSED_ARTICLE = '#e2e8f0';
export const EDGE = '#92cccf';
export const TOPIC_WIDTH = 50;
export const ARTICLE_WIDTH = 30;
export const SELECTION_FACTOR = 4.5; // on node selection
export const FACTOR = 0.8;
